<template>
  <section
    :class="$customSectionClasses(props.cfgs)"
    class="article-slider__root"
  >
    <div class="container">
      <div class="title-block">
        <div class="h2">{{ values.title }}</div>
        <NuxtLink to="/blog">
          <img
            src="~/assets/img/svg/common/arrow-right-black.svg"
            alt="arrow"
          >
        </NuxtLink>
      </div>
      <div class="home-news__slider-wrap">
        <swiper
          :space-between="10"
          :slides-per-view="1.2"
          :centered-slides="false"
          :effect="'fade'"

          :breakpoints="{
            [Breakpoint.xl]: {
              slidesPerView: 4,
              spaceBetween: 0
            },
            [Breakpoint.sm]: {
              slidesPerView: 2,
              spaceBetween: 5
            }
          }"
          class="home-news__swiper"
          @swiper="newsBlockSwiper.setSliderRef"
        >
          <swiper-slide
            v-for="item in news"
            :key="`newa-home-${item.id}`"
            class="adaptive-img home-news__slide"
          >
            <BlogCard
              :news="item as IArticleItem"
              :small-tag="true"
            />
          </swiper-slide>
        </swiper>
        <div
          v-if="news && news?.length > 3"
          class="home-news__controls"
        >
          <div class="slider-nav-count"><span>{{ activeIndex }}</span> — <span>{{ news?.length }}</span></div>
          <div class="home-news__nav slider-nav">
            <button
              :class="{'disabled': newsBlockSliderRef?.isBeginning}"
              class="slider-nav-btns --prev"
              @click="newsBlockSwiper.prevSlide"
            />

            <button
              :class="{'disabled':newsBlockSliderRef?.isEnd}"
              class="slider-nav-btns --next"
              @click="newsBlockSwiper.nextSlide"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue';
import type { Swiper as SwiperClass } from 'swiper/types';
import type { IBlockDefaultProps } from '#sitis/internal/models/common';
import type { SectionTypes } from '~/plugins/customSectionClasses';
import { setCustomSwiper } from '~/utils/swiper/setCustomSwiper';
import type { IArticleItem } from '#sitis/internal/controllers/articles/models/ArticleItem';
import {Breakpoint} from '~/enums/Breakpoint';

const { $customSectionClasses, $api } = useNuxtApp();

type ArticlesSliderBlockType = Omit<IBlockDefaultProps, 'values' | 'cfgs'> & {
	values: {
		title: string;
		content_type: string;
	};

	cfgs: SectionTypes;
};

const props = defineProps<ArticlesSliderBlockType>();
type HomeNewsBlockType = Partial<IArticleItem>;

const getArticles = async () => {
  const contType = props.values?.content_type === 'news' ? 'Новости' : 'Статьи';
  return await $api.agent.get(`/articles?filter[tag]=${contType}&page=1&expand=image,tags`)
    .then((res) => res._data)
    .catch((err: any) => {
      console.log('error in block articles');
      return null;
    });
};

const { data: news } = await useAsyncData(`home-news-${props?.values?.content_type || 'news'}`, () => getArticles(), {
  transform: (articles): HomeNewsBlockType[] | null => {
    if (articles) {
      return articles.map((t: any) => ({
        imageId: t.image_id,
        title: t.title,
        id: t.id,
        content: t.content,
        announce: t.announce,
        url: t.url,
        date: t.date,
        tags: t.tags
      }));
    }

    return null;
  }
});

const newsBlockSliderRef = ref<SwiperClass | null>(null);
const newsBlockSwiper = setCustomSwiper(newsBlockSliderRef);

const activeIndex = computed(() => {
  if (newsBlockSliderRef.value) {
    return newsBlockSliderRef.value.activeIndex + 1;
  }
  return 1;
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.home-news__swiper {
}

.home-news__slide {
	height: auto;
	padding: 0 10px 60px;

	&:deep(.blog__card-root) {
		height: 100%;
	}
}

.home-news__nav {
	position: relative;
	bottom: initial;
	right: initial;
	justify-content: flex-end;
	gap: 10px;
}

.home-news__controls {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: -20px;
}

.title-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  & .h2 {
    margin-bottom: 0;
  }

  a {
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background: var(--secondary);
    border-radius: 50%;
    display: none;
    box-shadow: 0 0 0 0 rgb(0 0 0 / 4%), 0 2px 4px 0 rgb(0 0 0 / 4%), 0 7px 7px 0 rgb(0 0 0 / 4%), 0 16px 9px 0 rgb(0 0 0 / 3%), 0 28px 11px 0 rgb(0 0 0 / 1%), 0 44px 12px 0 rgb(0 0 0 / 0%);

    img {
      filter: invert(6%) sepia(81%) saturate(5461%) hue-rotate(264deg) brightness(93%) contrast(87%);
    }
  }
}

@include media('sm') {
	.home-news__controls {
		margin-top: 0;
	}

	.home-news__slide {
		height: auto;
		padding: 0 0 20px;
	}

	.home-news__slider-wrap {
		position: relative;
		margin: 0 -20px;
		padding: 0 20px;
    width: calc(100% + 40px);
	}

	.home-news__swiper {
		margin: 0 -20px;
		padding: 0 20px;
		width: auto;
	}

  .title-block {
    a {
      display: flex;
    }
  }
}
</style>
